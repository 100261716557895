import {Component, inject} from '@angular/core';
import {Validators} from '@angular/forms';
import {AlarmConstant, CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {AlarmService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {IAlarmCreateRequest, IAlarmResponse} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-alarm-create-modal',
  templateUrl: './alarm-create-modal.component.html',
  styleUrls: ['./alarm-create-modal.component.scss'],
})
export class AlarmCreateModalComponent extends BaseCUDModalComponent<IAlarmResponse, IAlarmCreateRequest> {
  private alarmService = inject(AlarmService);

  constructor() {
    super();
    this.initForm();
  }

  doRequestObs(requestBody: IAlarmCreateRequest) {
    return this.alarmService.createAlarmObs(requestBody);
  }

  focusInputColor() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.COLOR_EXIST);
  }

  focusInputLevel() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.LEVEL_EXIST);
  }

  focusInputName() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.NAME_EXIST);
  }

  getRequestParams(): IAlarmCreateRequest {
    return this.form.value;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackCreateFailed(ModalConstant.ENTITY.ALARM);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackCreateSuccess(ModalConstant.ENTITY.ALARM);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.ALARM_CREATE);
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.COLOR]: ['#dc3545', [Validators.required]],
      [CommonConstant.FIELD.COOLDOWN_TIME]: [
        AlarmConstant.COOLDOWN_TIME.MIN,
        [
          Validators.max(AlarmConstant.COOLDOWN_TIME.MAX),
          Validators.min(AlarmConstant.COOLDOWN_TIME.MIN),
          Validators.required,
        ],
      ],
      [CommonConstant.FIELD.LEVEL]: [
        AlarmConstant.LEVEL.MIN,
        [Validators.min(AlarmConstant.LEVEL.MIN), Validators.required],
      ],
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
    });
    this.onFormChangeValues();
  }
}

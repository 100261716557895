<ng-container *ngIf="!feedback()">
  <div class="modal-body">
    <div class="d-flex flex-column align-items-center p-4">
      <div class="h-6rem w-6rem">
        <i class="bi bi-question-circle-fill display-4 text-info"></i>
      </div>
      <span class="font-bold fs-4 block mb-4 mt-2 fw-semibold">Xác nhận</span>
      <p class="mb-0">
        Bạn có chắc chắn muốn xoá sự kiện <b>{{ stationEvent.name }}</b>
      </p>
    </div>
  </div>
  <app-footer-modal
    [isDelete]="true"
    [isLoading]="isLoading"
    (eventCancel)="onClose()"
    (eventSubmit)="onDelete()"
  ></app-footer-modal>
</ng-container>
<app-feedback-modal [feedback]="feedback()" (eventBack)="onBack()" (eventClose)="onClose()"></app-feedback-modal>

import {Component, inject, OnInit} from '@angular/core';
import {ModalConstant} from '@rcms/constants';
import {UserService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {IUserResponse} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-user-delete-modal',
  templateUrl: './user-delete-modal.component.html',
  styleUrls: ['./user-delete-modal.component.scss'],
})
export class UserDeleteModalComponent extends BaseCUDModalComponent<IUserResponse, string> implements OnInit {
  private userService = inject(UserService);

  user!: IUserResponse;

  ngOnInit() {
    this.user = this.data;
  }

  override doRequestObs(userId: string) {
    return this.userService.deleteUserByIdObs(userId);
  }

  override getRequestParams(): string {
    return this.user.id;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackDeleteFailed(ModalConstant.ENTITY.USER);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackDeleteSuccess(ModalConstant.ENTITY.USER);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.USER_DELETE);
  }
}

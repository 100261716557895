import {ICreatedAndModifiedResponse, IEntityInteraction} from './common.type';
import {IUserResponse} from './user.type';

export interface ICompanyResponse extends ICreatedAndModifiedResponse {
  id: string;
  name: string;
  description: string;
  interaction: IEntityInteraction;
  isSystemOwner: boolean;
  noOfAreas: number;
  users: IUserResponse[];
}

export interface ICompanyCreateRequest {
  description?: string;
  name: string;
}

export interface ICompanyUpdateRequest {
  description?: string;
  name?: string;
}

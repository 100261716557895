import {Component, inject, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {CommonConstant, ErrorConstant, ModalConstant, UserConstant} from '@rcms/constants';
import {RoleNamePipe} from '@rcms/pipes';
import {RoleService, UserService} from '@rcms/services';
import {UserStore} from '@rcms/states/akita/stores';
import {ISelection, IUserResponse, IUserUpdateRequest} from '@rcms/types';
import {CommonUtil} from '@rcms/utils';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

type TRequestParams = [string, IUserUpdateRequest];

@Component({
  selector: 'app-user-update-modal',
  templateUrl: './user-update-modal.component.html',
  styleUrls: ['./user-update-modal.component.scss'],
})
export class UserUpdateModalComponent extends BaseCUDModalComponent<IUserResponse, TRequestParams> implements OnInit {
  private roleService = inject(RoleService);
  private userService = inject(UserService);
  private userStore = inject(UserStore);

  isGettingRoles = false;
  roleDatasets: ISelection[] = [];
  roleNamePipe = new RoleNamePipe();
  user!: IUserResponse;

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.user = this.data;
    this.patchDataForm(this.user);
    this.getRoleDatasets();
  }

  doRequestObs(requestParams: TRequestParams) {
    return this.userService.updateUserByIdObs(...requestParams);
  }

  focusInputUsername() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.USERNAME_EXIST);
  }

  getRequestParams(): TRequestParams {
    return [this.user.id, this.form.value];
  }

  getRoleDatasets() {
    this.isGettingRoles = true;
    this.roleDatasets = [];

    this.roleService.getAllRolesObs({}).subscribe({
      next: (roles) => {
        this.isGettingRoles = false;
        this.roleDatasets = roles.map(role => ({label: this.roleNamePipe.transform(role.name), value: role.id}));
      },
      error: () => this.isGettingRoles = false,
    });
  }

  handleRequestFailedSpecial() {
    this.setFeedbackUpdateFailed(ModalConstant.ENTITY.USER);
  }

  handleRequestSuccessSpecial(user: IUserResponse) {
    this.userStore.update(user.id, user);
    this.setFeedbackUpdateSuccess(ModalConstant.ENTITY.USER);
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.USERNAME]: [
        '',
        [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required],
      ],
      [CommonConstant.FIELD.PASSWORD]: [
        '',
        [
          Validators.minLength(UserConstant.PASSWORD.LENGTH.MIN),
          Validators.maxLength(UserConstant.PASSWORD.LENGTH.MAX),
          Validators.required,
        ],
      ],
      [CommonConstant.FIELD.FULL_NAME]: [
        '',
        [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required],
      ],
      [CommonConstant.FIELD.ROLE_ID]: ['', Validators.required],
      [CommonConstant.FIELD.ACTIVE]: [false],
    });
  }

  onChangeRoleId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.ROLE_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.ROLE_NOT_FOUND);
  }

  patchDataForm(data: IUserResponse) {
    const passwordDecrypted = CommonUtil.aesDecrypt(data.password, data.id);
    this.form.patchValue({
      username: data.username,
      password: passwordDecrypted,
      fullName: data.fullName,
      roleId: data.roleId,
      active: data.active,
    });
    this.onFormChangeValues();
  }
}

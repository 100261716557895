<ng-container *ngIf="!feedback()">
  <div class="modal-body">
    <form class="mx-3 form-group" [formGroup]="form" (ngSubmit)="onSave()">
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputName" class="form-label">Tên sự kiện</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          type="text"
          class="form-control"
          id="inputName"
          placeholder="Nhập tên"
          formControlName="name"
          [ngClass]="{
            'is-invalid':
              ((isInvalid || form.get('name')?.touched) && form.get('name')?.invalid) || feedbackErrors['nameExist'],
          }"
          (focus)="focusInputName()"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('name')?.errors?.['maxlength']">Độ dài quá 256 ký tự</p>
          <p *ngIf="form.get('name')?.errors?.['required']">Tên không được để trống</p>
          <p *ngIf="feedbackErrors['nameExist']">Tên đã tồn tại</p>
        </div>
      </div>
      <div class="mb-3">
        <label for="inputDescription" class="form-label">Mô tả</label>
        <textarea
          rows="5"
          class="form-control"
          id="inputDescription"
          placeholder="Nhập mô tả"
          formControlName="description"
          [ngClass]="{
            'is-invalid': (isInvalid || form.get('description')?.touched) && form.get('description')?.invalid,
          }"
        >
        </textarea>
        <div class="invalid-feedback">
          <p *ngIf="form.get('description')?.errors?.['maxlength']">Độ dài quá 512 ký tự</p>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputCode" class="form-label">Mã sự kiện</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          type="text"
          class="form-control"
          id="inputCode"
          placeholder="Nhập mã sự kiện"
          formControlName="code"
          oninput="this.value = this.value.replace(/^0+|[^0-9]/g, '');"
          [ngClass]="{
            'is-invalid':
              ((isInvalid || form.get('code')?.touched) && form.get('code')?.invalid) || feedbackErrors['codeExist'],
          }"
          (focus)="focusInputCode()"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('code')?.errors?.['min']">Mã sự kiện nhỏ hơn 1</p>
          <p *ngIf="form.get('code')?.errors?.['required']">Mã sự kiện không được để trống</p>
          <p *ngIf="feedbackErrors['codeExist']">Mã sự kiện đã tồn tại</p>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <p class="form-label">Cảnh báo</p>
        </div>
        <div class="w-100">
          <app-selection-single
            [datasets]="alarmDatasets"
            [hasInvalid]="
              ((isInvalid || form.get('alarmId')?.touched) && form.get('alarmId')?.invalid) ||
              feedbackErrors['alarmNotFound']
            "
            [hasSearch]="true"
            [initDataSelected]="alarmUnassigned"
            [isGettingDatasets]="isGettingAlarms"
            [ngClass]="{
              'is-invalid':
                ((isInvalid || form.get('alarmId')?.touched) && form.get('alarmId')?.invalid) ||
                feedbackErrors['alarmNotFound'],
            }"
            [position]="'top'"
            (changeSelectionEvent)="onChangeAlarmId($event)"
            (searchValueEvent)="getAlarmDatasets($event)"
          ></app-selection-single>
          <div class="invalid-feedback">
            <p *ngIf="feedbackErrors['alarmNotFound']">Cảnh báo không còn tồn tại</p>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <p class="form-label">Sự kiện khôi phục</p>
        </div>
        <div class="w-100">
          <app-selection-single
            [datasets]="stationEventResetsDatasets"
            [hasInvalid]="
              ((isInvalid || form.get('stationEventResetId')?.touched) && form.get('stationEventResetId')?.invalid) ||
              feedbackErrors['stationEventResetNotFound']
            "
            [hasSearch]="true"
            [initDataSelected]="stationEventResetUnassigned"
            [isGettingDatasets]="isGettingStationEventResets"
            [ngClass]="{
              'is-invalid':
                ((isInvalid || form.get('stationEventResetId')?.touched) && form.get('stationEventResetId')?.invalid) ||
                feedbackErrors['stationEventResetNotFound'],
            }"
            [position]="'top'"
            (changeSelectionEvent)="onChangeStationEventResetId($event)"
            (searchValueEvent)="getStationEventResetDatasets($event)"
          ></app-selection-single>
          <div class="invalid-feedback">
            <p *ngIf="feedbackErrors['stationEventResetNotFound']">Sự kiện không còn tồn tại</p>
          </div>
        </div>
      </div>
    </form>
  </div>
  <app-footer-modal
    [buttonSubmitDisabled]="!hasChange"
    [isLoading]="isLoading"
    (eventCancel)="onClose()"
    (eventSubmit)="onSave()"
  ></app-footer-modal>
</ng-container>
<app-feedback-modal [feedback]="feedback()" (eventBack)="onBack()" (eventClose)="onClose()"></app-feedback-modal>

import {inject, Injectable} from '@angular/core';
import {HttpEvent} from '@angular/common/http';
import {FileApi} from '@rcms/apis';
import {LocalStorage} from '@rcms/states/local';
import {GlobalSignal} from '@rcms/states/signals';
import {
  IFileStorageDownloadRequest,
  IFileStorageImageThumbnailSize,
  IFileStorageResponse,
  IFileStorageUploadRequest,
  IFileStorageUploadResponse,
  IFileStorageUploadTokenRequest,
  IFileStorageUploadTokenResponse,
} from '@rcms/types';
import {Observable} from 'rxjs';

type TImageThumbnailProps = {
  fileStorage: IFileStorageResponse | null;
  defaultUrl?: string;
  thumbnailSize?: IFileStorageImageThumbnailSize;
}

@Injectable({providedIn: 'root'})
export class FileService {
  private fileApi = inject(FileApi);

  getFileUploadTokenObs(requestBody: IFileStorageUploadTokenRequest): Observable<IFileStorageUploadTokenResponse> {
    return this.fileApi.getFileUploadToken(requestBody);
  }

  getImageThumbnailUrl({fileStorage, defaultUrl, thumbnailSize}: TImageThumbnailProps): string {
    if (!fileStorage?.originalName) {
      return defaultUrl ?? '';
    }
    const token = GlobalSignal.token() || LocalStorage.getToken();
    const fileStorageDownloadBody: IFileStorageDownloadRequest = {
      fileStorageEncoded: fileStorage.encoded,
      thumbnailSize: thumbnailSize ?? {width: 32, height: 32},
      accessToken: token ?? '',
    };
    return this.fileApi.getFileUrl(fileStorage.originalName, fileStorageDownloadBody);
  }

  getFileUrl(fileStorage: IFileStorageResponse): string {
    if (!fileStorage?.originalName) {
      return '';
    }
    const token = GlobalSignal.token() || LocalStorage.getToken();
    const fileStorageDownloadBody: IFileStorageDownloadRequest = {
      fileStorageEncoded: fileStorage.encoded,
      accessToken: token ?? '',
    };
    return this.fileApi.getFileUrl(fileStorage.originalName, fileStorageDownloadBody);
  }

  uploadFileObs(
    accessToken: string,
    formData: FormData,
    requestBody: IFileStorageUploadRequest,
  ): Observable<HttpEvent<IFileStorageUploadResponse>> {
    return this.fileApi.uploadFile(accessToken, formData, requestBody);
  }
}

import {Component, EventEmitter, inject, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {CommonConstant, CoreConstant, FileConstant} from '@rcms/constants';
import {FileService, UserService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {IFileStorageUploadTokenRequest, IUserResponse, IUserUpdateMyProfileRequest} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-user-update-my-profile-modal',
  templateUrl: './user-update-my-profile-modal.component.html',
  styleUrls: ['./user-update-my-profile-modal.component.scss'],
})
export class UserUpdateMyProfileModalComponent extends BaseCUDModalComponent<IUserResponse, IUserUpdateMyProfileRequest>
  implements OnInit {
  private fileService = inject(FileService);
  private userService = inject(UserService);

  eventUpload$: EventEmitter<IFileStorageUploadTokenRequest> = new EventEmitter();

  hasChangeAvatar = false;
  userAvatarUrl = '';
  user!: IUserResponse;

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.user = this.data;
    this.userAvatarUrl = this.fileService.getImageThumbnailUrl({
      fileStorage: this.user.avatar,
      thumbnailSize: {height: 100, width: 100},
      defaultUrl: '',
    });
    this.patchDataForm(this.user);
  }

  doRequestObs(requestBody: IUserUpdateMyProfileRequest) {
    return this.userService.updateUserMyProfileObs(requestBody);
  }

  getRequestParams(): IUserUpdateMyProfileRequest {
    return this.form.value;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackUpdateFailed();
  }

  handleRequestSuccessSpecial(user: IUserResponse) {
    const userAvatarUrl = this.fileService.getImageThumbnailUrl({
      fileStorage: user.avatar,
      thumbnailSize: {height: 100, width: 100},
      defaultUrl: CoreConstant.IMAGES.USER_AVATAR,
    });
    GlobalSignal.me.set(user);
    GlobalSignal.meAvatarUrl.set(userAvatarUrl);
    this.setFeedbackUpdateSuccess();
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.FULL_NAME]: [
        '',
        [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required],
      ],
    });
  }

  onFileUploadChange(fileSource: string | ArrayBuffer | null) {
    this.hasChangeAvatar = !!fileSource;
  }

  override onSaveSpecial = () => {
    if (this.hasChangeAvatar) {
      this.isLoading = true;
      this.eventUpload$.next({
        companyId: this.user.companyId,
        entity: FileConstant.UPLOAD_ENTITY.USER,
        entityId: this.user.id,
        fieldName: FileConstant.UPLOAD_FIELD.AVATAR,
      });
    } else {
      super.onSaveCommon();
    }
  };

  onUploadSuccess(originName: string) {
    const requestBody: IUserUpdateMyProfileRequest = {avatarOriginalName: originName};
    if (this.hasChange && !this.form.invalid) {
      const {fullName} = this.form.value;
      requestBody.fullName = fullName;
    }
    this.userService.updateUserMyProfileObs(requestBody).subscribe({
      next: (data) => this.handleRequestSuccess(data),
      error: () => this.handleRequestFailed(),
    });
  }

  patchDataForm(data: IUserResponse) {
    this.form.patchValue({fullName: data.fullName});
    this.onFormChangeValues();
  }
}

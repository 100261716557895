import {Component, inject, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {ApiConstant, CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {AreaService, CompanyService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {IAreaCreateRequest, IAreaResponse, ISelection, LoopBackFilters} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-area-create-modal',
  templateUrl: './area-create-modal.component.html',
  styleUrls: ['./area-create-modal.component.scss'],
})
export class AreaCreateModalComponent extends BaseCUDModalComponent<IAreaResponse, IAreaCreateRequest>
  implements OnInit {
  private areaService = inject(AreaService);
  private companyService = inject(CompanyService);

  companyMaintenanceDatasets: ISelection[] = [];
  companyObserverDatasets: ISelection[] = [];
  isGettingMaintenanceCompanies = false;
  isGettingObserverCompanies = false;

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.getCompanyMaintenanceDatasets();
    this.getCompanyObserverDatasets();
  }

  doRequestObs(requestBody: IAreaCreateRequest) {
    return this.areaService.createAreaObs(requestBody);
  }

  focusInputName() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.NAME_EXIST);
  }

  getCompanyMaintenanceDatasets(name?: string) {
    this.isGettingMaintenanceCompanies = true;
    this.companyMaintenanceDatasets = [];

    const filters = new LoopBackFilters().setLimit(ApiConstant.LOOPBACK_FILTER.LIMIT_MAX_SIZE);
    if (name) {
      filters.setWhere({name: ApiUtil.generateLoopbackFilterLike(name)});
    }
    this.companyService.getAllCompaniesObs(filters.serialize()).subscribe({
      next: (companies) => {
        this.isGettingMaintenanceCompanies = false;
        this.companyMaintenanceDatasets = companies.map(company => ({label: company.name, value: company.id}));
      },
      error: () => this.isGettingMaintenanceCompanies = false,
    });
  }

  getCompanyObserverDatasets(name?: string) {
    this.isGettingObserverCompanies = true;
    this.companyObserverDatasets = [];

    const filters = new LoopBackFilters().setLimit(ApiConstant.LOOPBACK_FILTER.LIMIT_MAX_SIZE);
    if (name) {
      filters.setWhere({name: ApiUtil.generateLoopbackFilterLike(name)});
    }
    this.companyService.getAllCompaniesObs(filters.serialize()).subscribe({
      next: (companies) => {
        this.isGettingObserverCompanies = false;
        this.companyObserverDatasets = companies.map(company => ({label: company.name, value: company.id}));
      },
      error: () => this.isGettingObserverCompanies = false,
    });
  }

  getRequestParams(): IAreaCreateRequest {
    return this.form.value;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackCreateFailed(ModalConstant.ENTITY.AREA);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackCreateSuccess(ModalConstant.ENTITY.AREA);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.AREA_CREATE);
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      [CommonConstant.FIELD.COMPANY_MAINTENANCE_ID]: ['', [Validators.required]],
      [CommonConstant.FIELD.COMPANY_OBSERVER_ID]: ['', [Validators.required]],
    });
    this.onFormChangeValues();
  }

  onChangeCompanyMaintenanceId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.COMPANY_MAINTENANCE_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.COMPANY_MAINTENANCE_NOT_FOUND);
  }

  onChangeCompanyObserverId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.COMPANY_OBSERVER_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.COMPANY_OBSERVER_NOT_FOUND);
  }
}

import {ErrorConstant} from '@rcms/constants';
import {TObjectValues} from './common.type';

export interface IErrorResponse {
  code: string;
  details: string[];
  message: string;
  name: string;
  statusCode: number;
}

export type TErrorFlag = TObjectValues<typeof ErrorConstant.ERROR_FLAG>;

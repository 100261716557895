import {Component, inject} from '@angular/core';
import {Validators} from '@angular/forms';
import {CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {TrainStationService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {ITrainStationCreateRequest, ITrainStationResponse} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-train-station-create-modal',
  templateUrl: './train-station-create-modal.component.html',
  styleUrls: ['./train-station-create-modal.component.scss'],
})
export class TrainStationCreateModalComponent
  extends BaseCUDModalComponent<ITrainStationResponse, ITrainStationCreateRequest> {
  private trainStationService = inject(TrainStationService);

  constructor() {
    super();
    this.initForm();
  }

  doRequestObs(requestBody: ITrainStationCreateRequest) {
    return this.trainStationService.createTrainStationObs(requestBody);
  }

  focusInputName() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.NAME_EXIST);
  }

  getRequestParams(): ITrainStationCreateRequest {
    return this.form.value;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackCreateFailed(ModalConstant.ENTITY.TRAIN_STATION);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackCreateSuccess(ModalConstant.ENTITY.TRAIN_STATION);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.TRAIN_STATION_CREATE);
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      [CommonConstant.FIELD.ADDRESS]: [
        '',
        [Validators.maxLength(CommonConstant.LIMIT.LENGTH_512), Validators.required],
      ],
    });
    this.onFormChangeValues();
  }
}

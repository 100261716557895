import {Component, EventEmitter, inject, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {AlarmConstant, CommonConstant, ErrorConstant, FileConstant, ModalConstant} from '@rcms/constants';
import {AlarmService, FileService} from '@rcms/services';
import {AlarmStore} from '@rcms/states/akita/stores';
import {GlobalSignal} from '@rcms/states/signals';
import {IAlarmResponse, IAlarmUpdateRequest, IFileStorageUploadTokenRequest} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

type TRequestParams = [string, IAlarmUpdateRequest];

@Component({
  selector: 'app-alarm-update-modal',
  templateUrl: './alarm-update-modal.component.html',
  styleUrls: ['./alarm-update-modal.component.scss'],
})
export class AlarmUpdateModalComponent extends BaseCUDModalComponent<IAlarmResponse, TRequestParams> implements OnInit {
  private alarmService = inject(AlarmService);
  private alarmStore = inject(AlarmStore);
  private fileService = inject(FileService);

  alarm!: IAlarmResponse;
  eventUpload$: EventEmitter<IFileStorageUploadTokenRequest> = new EventEmitter();
  hasChangeSound = false;
  soundUrl = '';

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.alarm = this.data;
    if (this.alarm.sound) {
      this.soundUrl = this.fileService.getFileUrl(this.alarm.sound);
    }
    this.patchDataForm(this.alarm);
  }

  doRequestObs(requestBody: TRequestParams) {
    return this.alarmService.updateAlarmByIdObs(...requestBody);
  }

  focusInputColor() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.COLOR_EXIST);
  }

  focusInputLevel() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.LEVEL_EXIST);
  }

  focusInputName() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.NAME_EXIST);
  }

  getRequestParams(): TRequestParams {
    return [this.alarm.id, this.form.value];
  }

  handleRequestFailedSpecial() {
    this.setFeedbackUpdateFailed(ModalConstant.ENTITY.ALARM);
  }

  handleRequestSuccessSpecial(alarm: IAlarmResponse) {
    this.alarmStore.update(alarm.id, alarm);
    this.setFeedbackUpdateSuccess(ModalConstant.ENTITY.ALARM);
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.COLOR]: ['#dc3545', [Validators.required]],
      [CommonConstant.FIELD.LEVEL]: [
        AlarmConstant.LEVEL.MIN,
        [Validators.min(AlarmConstant.LEVEL.MIN), Validators.required],
      ],
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
    });
    this.onFormChangeValues();
  }

  onFileUploadChange(fileSource: string | ArrayBuffer | null) {
    this.hasChangeSound = !!fileSource;
  }

  onUploadFailed() {
    this.isLoading = false;
  }

  onUploadSuccess(originName: string) {
    let requestBody: IAlarmUpdateRequest = {soundOriginalName: originName};
    if (this.hasChange && !this.form.invalid) {
      requestBody = {...requestBody, ...this.form.value};
    }
    this.alarmService.updateAlarmByIdObs(this.alarm.id, requestBody).subscribe({
      next: (data) => this.handleRequestSuccess(data),
      error: () => this.handleRequestFailed(),
    });
  }

  override onSaveSpecial = () => {
    if (this.hasChangeSound) {
      this.isLoading = true;
      this.eventUpload$.next({
        companyId: GlobalSignal.me()?.companyId ?? '',
        entity: FileConstant.UPLOAD_ENTITY.ALARM,
        entityId: this.alarm.id,
        fieldName: FileConstant.UPLOAD_FIELD.SOUND,
      });
    } else {
      super.onSaveCommon();
    }
  };

  patchDataForm(data: IAlarmResponse) {
    this.form.patchValue({
      name: data.name,
      color: data.color,
      level: data.level,
    });
    this.onFormChangeValues();
  }
}

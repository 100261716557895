import {Component, OnInit} from '@angular/core';
import {CommonConstant} from '@rcms/constants';
import {IRoleResponse, TEntity, TRolePermissions} from '@rcms/types';
import {CommonUtil, RoleUtil} from '@rcms/utils';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview2';
import {BaseModalComponent} from '../base-modal.component';

@Component({
  selector: 'app-role-detail',
  templateUrl: './role-detail-modal.component.html',
  styleUrls: ['./role-detail-modal.component.scss'],
})
export class RoleDetailModalComponent extends BaseModalComponent<IRoleResponse> implements OnInit {
  permissionTree: TreeviewItem[] = [];
  role!: IRoleResponse;
  treeConfig: TreeviewConfig = {
    decoupleChildFromParent: false,
    hasAllCheckBox: false,
    hasCollapseExpand: false,
    hasDivider: true,
    hasFilter: false,
    maxHeight: 500,
  };

  ngOnInit() {
    this.role = this.data;
    this.permissionsToTreeView(this.role.permissions);
  }

  permissionsToTreeView(permissions: TRolePermissions) {
    (Object.keys(permissions) as TEntity[]).forEach(entity => {
      const excludeEntities: TEntity[] = [CommonConstant.ENTITY.OTA, CommonConstant.ENTITY.TRAIN_STATION];
      if (!excludeEntities.includes(entity)) {
        const treeItem = new TreeviewItem({
          disabled: true,
          text: CommonUtil.getEntityDisplay(entity),
          value: entity,
          children: permissions[entity]?.map(action => ({
            disabled: true,
            text: RoleUtil.getPermissionActionDisplay(action),
            value: `${entity}.${action}`,
          })),
        });
        this.permissionTree.push(treeItem);
      }
    });
  }
}

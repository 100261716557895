import {Component, inject, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {ApiConstant, CommonConstant, ErrorConstant, ModalConstant, StationEventConstant} from '@rcms/constants';
import {AlarmService, StationEventService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {ISelection, IStationEventResponse, IStationEventUpdateRequest, LoopBackFilters} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

type TRequestParams = [string, IStationEventUpdateRequest];

@Component({
  selector: 'app-station-event-update-modal',
  templateUrl: './station-event-update-modal.component.html',
  styleUrls: ['./station-event-update-modal.component.scss'],
})
export class StationEventUpdateModalComponent extends BaseCUDModalComponent<IStationEventResponse, TRequestParams>
  implements OnInit {
  private alarmService = inject(AlarmService);
  private stationEventService = inject(StationEventService);

  alarmDatasets: ISelection[] = [];
  readonly alarmUnassigned: ISelection = {label: 'Không gán cảnh báo', value: null};
  initAlarmSelected!: ISelection;
  initStationEventResetSelected!: ISelection;
  isGettingAlarms = false;
  isGettingStationEventResets = false;
  stationEvent!: IStationEventResponse;
  stationEventResetsDatasets: ISelection[] = [];
  readonly stationEventResetUnassigned: ISelection = {label: 'Không gán sự kiện khôi phục', value: null};

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.stationEvent = this.data;
    this.initAlarmSelected = this.stationEvent.alarm
      ? {label: this.stationEvent.alarm.name, value: this.stationEvent.alarm.id}
      : this.alarmUnassigned;
    this.initStationEventResetSelected = this.stationEvent.stationEventReset
      ? {label: this.stationEvent.stationEventReset.name, value: this.stationEvent.stationEventReset.id}
      : this.stationEventResetUnassigned;
    this.patchDataForm(this.stationEvent);
    this.getAlarmDatasets();
    this.getStationEventResetDatasets();
  }

  doRequestObs(requestParams: TRequestParams) {
    return this.stationEventService.updateStationEventByIdObs(...requestParams);
  }

  focusInputCode() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.CODE_EXIST);
  }

  focusInputName() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.NAME_EXIST);
  }

  getAlarmDatasets(name?: string) {
    this.isGettingAlarms = true;
    this.alarmDatasets = [];

    this.alarmDatasets.push(this.alarmUnassigned);

    const filters = new LoopBackFilters()
      .setLimit(ApiConstant.LOOPBACK_FILTER.LIMIT_MAX_SIZE)
      .setOrder(`${CommonConstant.FIELD.LEVEL} ASC`);

    if (name) {
      filters.setWhere({name: ApiUtil.generateLoopbackFilterLike(name)});
    }
    this.alarmService.getAllAlarmsObs(filters.serialize()).subscribe({
      next: (alarms) => {
        this.isGettingAlarms = false;
        this.alarmDatasets.push(...alarms.map(alarm => ({label: alarm.name, value: alarm.id})));
      },
      error: () => this.isGettingAlarms = false,
    });
  }

  getStationEventResetDatasets(name?: string) {
    this.isGettingStationEventResets = true;
    this.stationEventResetsDatasets = [];

    this.stationEventResetsDatasets.push(this.stationEventResetUnassigned);

    const filters = new LoopBackFilters()
      .setLimit(ApiConstant.LOOPBACK_FILTER.LIMIT_MAX_SIZE)
      .setOrder(`${CommonConstant.FIELD.CODE} ASC`)
      .setWhere({id: {neq: this.stationEvent.id}});

    if (name) {
      filters.setWhere({name: ApiUtil.generateLoopbackFilterLike(name)});
    }
    this.stationEventService.getAllStationEventsObs(filters.serialize()).subscribe({
      next: (stationEvents) => {
        this.isGettingStationEventResets = false;
        this.stationEventResetsDatasets.push(
          ...stationEvents.map(stationEvent => ({label: stationEvent.name, value: stationEvent.id})),
        );
      },
      error: () => this.isGettingStationEventResets = false,
    });
  }

  getRequestParams(): TRequestParams {
    return [this.stationEvent.id, this.form.value];
  }

  handleRequestFailedSpecial() {
    this.setFeedbackCreateFailed(ModalConstant.ENTITY.STATION_EVENT);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackCreateSuccess(ModalConstant.ENTITY.STATION_EVENT);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.STATION_EVENT_CREATE);
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.ALARM_ID]: [null],
      [CommonConstant.FIELD.STATION_EVENT_RESET_ID]: [null],
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      [CommonConstant.FIELD.DESCRIPTION]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_512)]],
      [CommonConstant.FIELD.CODE]: ['', [Validators.min(StationEventConstant.CODE.MIN), Validators.required]],
    });
    this.onFormChangeValues();
  }

  onChangeAlarmId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.ALARM_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.ALARM_NOT_FOUND);
  }

  onChangeStationEventResetId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.STATION_EVENT_RESET_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.STATION_EVENT_RESET_NOT_FOUND);
  }

  patchDataForm(data: IStationEventResponse) {
    this.form.patchValue({
      alarmId: data.alarmId,
      stationEventResetId: data.stationEventResetId,
      name: data.name,
      description: data.description,
      code: data.code,
    });
    this.onFormChangeValues();
  }
}

import {ICreatedAndModifiedResponse, IEntityInteraction} from './common.type';
import {ICompanyResponse} from './company.type';
import {IStationResponse} from './station.type';

export interface IAreaResponse extends ICreatedAndModifiedResponse {
  id: string;
  companyMaintenanceId: string;
  companyObserverId: string;
  interaction: IEntityInteraction;
  name: string;
  companyMaintenance: ICompanyResponse;
  companyObserver: ICompanyResponse;
  stations: IStationResponse[];
}

export interface IAreaCreateRequest {
  companyMaintenanceId: string;
  companyObserverId: string;
  name: string;
}

export interface IAreaUpdateRequest {
  companyMaintenanceId?: string;
  companyObserverId?: string;
  name?: string;
}

import {inject, Pipe, PipeTransform} from '@angular/core';
import {FileService} from '@rcms/services';
import {IFileStorageResponse} from '@rcms/types';

@Pipe({name: 'fileStorageUrl', standalone: true})
export class FileStorageUrlPipe implements PipeTransform {
  private fileService = inject(FileService);

  transform(value: IFileStorageResponse | null): string {
    return value ? this.fileService.getFileUrl(value) : '';
  }
}

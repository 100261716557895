import {RoleConstant} from '@rcms/constants';
import {
  ICreatedAndModifiedResponse,
  IEntityInteraction,
  TEntity,
  TEntityIsUsedByOther,
  TObjectValues,
} from './common.type';
import {IUserResponse} from './user.type';

export interface IRoleResponse extends ICreatedAndModifiedResponse {
  id: string;
  description: string;
  interaction: IEntityInteraction;
  isUsedByOther: TEntityIsUsedByOther;
  name: TRoleName;
  permissions: TRolePermissions;
  users: IUserResponse[];
}

export type TRoleName = TObjectValues<typeof RoleConstant.ROLE_NAME>;
export type TRolePermissions = Partial<Record<TEntity, string[]>>;

import {inject, Injectable} from '@angular/core';
import {StationApi} from '@rcms/apis';
import {
  ICountResponse,
  ILoopBackFilters,
  IStationCreateRequest,
  IStationResponse,
  IStationUpdateRequest,
} from '@rcms/types';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class StationService {
  private stationApi = inject(StationApi);

  createStationObs(requestBody: IStationCreateRequest): Observable<IStationResponse> {
    return this.stationApi.createStation(requestBody);
  }

  getAllStationsObs(filters: ILoopBackFilters): Observable<IStationResponse[]> {
    return this.stationApi.getAllStations(filters);
  }

  getAllStationsSimulationObs(filters: ILoopBackFilters): Observable<IStationResponse[]> {
    return this.stationApi.getAllStationsSimulation(filters);
  }

  getCountAllStationsObs(where: object): Observable<ICountResponse> {
    return this.stationApi.getCountAllStations(where);
  }

  updateStationByIdObs(stationId: string, requestBody: IStationUpdateRequest): Observable<IStationResponse> {
    return this.stationApi.updateStationById(stationId, requestBody);
  }
}

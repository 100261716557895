import {IAreaResponse} from './area.type';
import {ICreatedAndModifiedResponse, IEntityInteraction} from './common.type';
import {ICompanyResponse} from './company.type';
import {IDeviceResponse} from './device.type';

export interface IStationResponse extends ICreatedAndModifiedResponse {
  id: string;
  address: number;
  areaId: string;
  deviceId: string | null;
  interaction: IEntityInteraction;
  isTrainStation: boolean;
  lastUpdate: string;
  name: string;
  simulation: object;
  area: IAreaResponse | null;
  companyMaintenance: ICompanyResponse | null;
  companyObserver: ICompanyResponse | null;
  device: IDeviceResponse | null;
}

export interface IStationCreateRequest {
  areaId: string | null;
  deviceId: string | null;
  name?: string;
  address: number;
  isTrainStation: boolean;
}

export interface IStationUpdateRequest {
  areaId?: string;
  deviceId?: string | null;
  name?: string;
  address?: number;
}

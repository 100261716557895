import {Component, inject, OnInit} from '@angular/core';
import {ModalConstant} from '@rcms/constants';
import {StationEventService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {IStationEventResponse} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-station-event-delete-modal',
  templateUrl: './station-event-delete-modal.component.html',
  styleUrls: ['./station-event-delete-modal.component.scss'],
})
export class StationEventDeleteModalComponent extends BaseCUDModalComponent<IStationEventResponse, string>
  implements OnInit {
  private stationEventService = inject(StationEventService);

  stationEvent!: IStationEventResponse;

  ngOnInit() {
    this.stationEvent = this.data;
  }

  doRequestObs(stationEventId: string) {
    return this.stationEventService.deleteStationEventByIdObs(stationEventId);
  }

  getRequestParams(): string {
    return this.stationEvent.id;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackDeleteFailed(ModalConstant.ENTITY.STATION_EVENT);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackDeleteSuccess(ModalConstant.ENTITY.STATION_EVENT);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.STATION_EVENT_DELETE);
  }
}

import {ICreatedAndModifiedResponse, IEntityInteraction} from './common.type';

export interface ITrainStationResponse extends ICreatedAndModifiedResponse {
  id: string;
  address: string;
  interaction: IEntityInteraction;
  name: string;
  noOfStationNorths: number;
  noOfStationSouths: number;
}

export interface ITrainStationCreateRequest {
  address: string;
  name: string;
}

export interface ITrainStationUpdateRequest {
  address?: string;
  name?: string;
}

export class RouteConstant {
  static readonly ROUTE_NAME = {
    ALARMS: 'alarms',
    AREAS: 'areas',
    AUTH: 'auth',
    COMPANIES: 'companies',
    DASHBOARD: 'dashboard',
    DATA_MANAGEMENT: 'data-management',
    DEVICES: 'devices',
    EVENTS: 'events',
    FORBIDDEN: '403',
    GENERAL: 'general',
    HOME: '',
    LOGIN: 'login',
    LOGOUT: 'logout',
    MY_PROFILE: 'my-profile',
    NOT_FOUND: '404',
    ROLES: 'roles',
    SPECIFICATIONS: 'specifications',
    STATIONS: 'stations',
    STATIONS_MANAGEMENT: 'stations-management',
    STATIONS_SIMULATION: 'stations-simulation',
    SYSTEM_SETTINGS: 'system-settings',
    TRAIN_STATIONS: 'train-stations',
    USERS: 'users',
    USERS_MANAGEMENT: 'users-management',
  } as const;
}

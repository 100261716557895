import {IFileStorageResponse} from '@rcms/types/file.type';
import {ICreatedAndModifiedResponse, IEntityInteraction} from './common.type';

export interface IAlarmResponse extends ICreatedAndModifiedResponse {
  id: string;
  color: string;
  cooldownTime: number;
  interaction: IEntityInteraction;
  level: number;
  name: string;
  sound: IFileStorageResponse | null;
}

export interface IAlarmCreateRequest {
  color: string;
  cooldownTime: number;
  level: number;
  name: string;
}

export interface IAlarmUpdateRequest {
  color?: string;
  level?: number;
  name?: string;
  soundOriginalName?: string;
}

import {Component, inject, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {DeviceService} from '@rcms/services';
import {DeviceStore} from '@rcms/states/akita/stores';
import {IDeviceResponse, IDeviceUpdateRequest} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

type TRequestParams = [string, IDeviceUpdateRequest];

@Component({
  selector: 'app-device-update-modal',
  templateUrl: './device-update-modal.component.html',
  styleUrls: ['./device-update-modal.component.scss'],
})
export class DeviceUpdateModalComponent extends BaseCUDModalComponent<IDeviceResponse, TRequestParams>
  implements OnInit {
  private deviceService = inject(DeviceService);
  private deviceStore = inject(DeviceStore);

  device!: IDeviceResponse;

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.device = this.data;
    this.patchDataForm(this.device);
  }

  doRequestObs(requestParams: TRequestParams) {
    return this.deviceService.updateDeviceByIdObs(...requestParams);
  }

  focusInputName() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.NAME_EXIST);
  }

  getRequestParams(): TRequestParams {
    return [this.device.id, this.form.value];
  }

  handleRequestFailedSpecial() {
    this.setFeedbackUpdateFailed(ModalConstant.ENTITY.DEVICE);
  }

  handleRequestSuccessSpecial(device: IDeviceResponse) {
    this.deviceStore.update(device.id, device);
    this.setFeedbackUpdateSuccess(ModalConstant.ENTITY.DEVICE);
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
    });
  }

  patchDataForm(data: IDeviceResponse) {
    this.form.patchValue({name: data.name});
    this.onFormChangeValues();
  }
}

import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiConstant} from '@rcms/constants';
import {IAlarmCreateRequest, IAlarmResponse, IAlarmUpdateRequest, ICountResponse, ILoopBackFilters} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';

@Injectable({providedIn: 'root'})
export class AlarmApi {
  private httpClient = inject(HttpClient);

  private basePath = ApiConstant.API_ENTITY.ALARMS;
  private prefixCount = 'count';

  createAlarm(requestBody: IAlarmCreateRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);

    return this.httpClient.post<IAlarmResponse>(url, requestBody);
  }

  deleteAlarmById(alarmId: string) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${alarmId}`);

    return this.httpClient.delete<void>(url);
  }

  getAllAlarms(filtersBody: ILoopBackFilters) {
    const url = ApiUtil.generateBackendUrlFromPath(this.basePath);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({filter: filtersBody});

    return this.httpClient.get<IAlarmResponse[]>(url, {params: queryParams});
  }

  getCountAllAlarms(whereBody: object) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${this.prefixCount}`);
    const queryParams = ApiUtil.convert2HttpParamsWithQuery({where: whereBody});

    return this.httpClient.get<ICountResponse>(url, {params: queryParams});
  }

  updateAlarmById(alarmId: string, requestBody: IAlarmUpdateRequest) {
    const url = ApiUtil.generateBackendUrlFromPath(`${this.basePath}/${alarmId}`);

    return this.httpClient.patch<IAlarmResponse>(url, requestBody);
  }
}

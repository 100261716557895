import {Pipe, PipeTransform} from '@angular/core';
import {compact as _compact, flatten as _flatten, uniq as _uniq} from 'lodash-es';

@Pipe({name: 'numberOfUniqueLists', standalone: true})
export class NumberOfUniqueListsPipe implements PipeTransform {
  transform(values: unknown[]): number {
    const flattenedArray = _flatten(values).map(value => JSON.stringify(value));
    const uniqueArray = _uniq(_compact(flattenedArray));
    return uniqueArray.length;
  }
}

<ng-container *ngIf="!feedback()">
  <div class="modal-body">
    <form class="mx-3 form-group" [formGroup]="form" (ngSubmit)="onSave()">
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputFullName" class="form-label">Tên</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          type="text"
          class="form-control"
          id="inputFullName"
          placeholder="Nhập tên"
          formControlName="fullName"
          [ngClass]="{'is-invalid': form.get('fullName')?.invalid}"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('fullName')?.errors?.['maxlength']">Độ dài quá 256 ký tự</p>
          <p *ngIf="form.get('fullName')?.errors?.['required']">Tên không được để trống</p>
        </div>
      </div>
      <div class="mb-3">
        <label for="imageProfile" class="form-label">Ảnh đại diện</label>
        <div id="imageProfile">
          <app-image-upload
            [entityImgSource]="userAvatarUrl"
            [eventUpload$]="eventUpload$"
            (eventFileSelect)="onFileUploadChange($event)"
            (eventUploadSuccess)="onUploadSuccess($event)"
          >
          </app-image-upload>
        </div>
      </div>
    </form>
  </div>
  <app-footer-modal
    [buttonSubmitDisabled]="!hasChange && !hasChangeAvatar"
    [isLoading]="isLoading"
    (eventCancel)="onClose()"
    (eventSubmit)="onSave()"
  ></app-footer-modal>
</ng-container>
<app-feedback-modal [feedback]="feedback()" (eventBack)="onBack()" (eventClose)="onClose()"></app-feedback-modal>

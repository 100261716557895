import {Component, inject, OnInit} from '@angular/core';
import {ModalConstant} from '@rcms/constants';
import {AreaService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {IAreaResponse} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-area-delete-modal',
  templateUrl: './area-delete-modal.component.html',
  styleUrls: ['./area-delete-modal.component.scss'],
})
export class AreaDeleteModalComponent extends BaseCUDModalComponent<IAreaResponse, string> implements OnInit {
  private areaService = inject(AreaService);

  area!: IAreaResponse;

  ngOnInit() {
    this.area = this.data;
  }

  doRequestObs(areaId: string) {
    return this.areaService.deleteAreaByIdObs(areaId);
  }

  getRequestParams(): string {
    return this.area.id;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackDeleteFailed(ModalConstant.ENTITY.AREA);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackDeleteSuccess(ModalConstant.ENTITY.AREA);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.AREA_DELETE);
  }
}

import {Component, inject, OnInit} from '@angular/core';
import {ModalConstant} from '@rcms/constants';
import {CompanyService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {ICompanyResponse} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-company-delete-modal',
  templateUrl: './company-delete-modal.component.html',
  styleUrls: ['./company-delete-modal.component.scss'],
})
export class CompanyDeleteModalComponent extends BaseCUDModalComponent<ICompanyResponse, string> implements OnInit {
  private companyService = inject(CompanyService);

  company!: ICompanyResponse;

  ngOnInit() {
    this.company = this.data;
  }

  doRequestObs(companyId: string) {
    return this.companyService.deleteCompanyByIdObs(companyId);
  }

  getRequestParams(): string {
    return this.company.id;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackDeleteFailed(ModalConstant.ENTITY.COMPANY);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackDeleteSuccess(ModalConstant.ENTITY.COMPANY);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.COMPANY_DELETE);
  }
}

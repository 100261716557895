import {ModalConstant} from '@rcms/constants';
import {TObjectValues} from '@rcms/types/common.type';
import {NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

export interface IModal<TData> {
  data: TData;
  modalRef: NgbModalRef;
  onClose: () => void;
}

export interface IModalComponent<TComponent> {
  component: TComponent;
  title: string;
}

export interface IModalData {
  data: unknown;
  modalName: TModalName;
  options?: NgbModalOptions;
}

export type TModalEntity = TObjectValues<typeof ModalConstant.ENTITY>;
export type TModalName = TObjectValues<typeof ModalConstant.NAME>;

import {Component, inject, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {CompanyService} from '@rcms/services';
import {CompanyStore} from '@rcms/states/akita/stores';
import {ICompanyResponse, ICompanyUpdateRequest} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

type TRequestParams = [string, ICompanyUpdateRequest];

@Component({
  selector: 'app-company-update-modal',
  templateUrl: './company-update-modal.component.html',
  styleUrls: ['./company-update-modal.component.scss'],
})
export class CompanyUpdateModalComponent extends BaseCUDModalComponent<ICompanyResponse, TRequestParams>
  implements OnInit {
  private companyService = inject(CompanyService);
  private companyStore = inject(CompanyStore);

  company!: ICompanyResponse;

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.company = this.data;
    this.patchDataForm(this.company);
  }

  doRequestObs(requestParams: TRequestParams) {
    return this.companyService.updateCompanyByIdObs(...requestParams);
  }

  focusInputName() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.NAME_EXIST);
  }

  getRequestParams(): TRequestParams {
    return [this.company.id, this.form.value];
  }

  handleRequestFailedSpecial() {
    this.setFeedbackUpdateFailed(ModalConstant.ENTITY.COMPANY);
  }

  handleRequestSuccessSpecial(company: ICompanyResponse) {
    this.companyStore.update(company.id, company);
    this.setFeedbackUpdateSuccess(ModalConstant.ENTITY.COMPANY);
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      [CommonConstant.FIELD.DESCRIPTION]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_512)]],
    });
  }

  patchDataForm(data: ICompanyResponse) {
    this.form.patchValue({name: data.name, description: data.description});
    this.onFormChangeValues();
  }
}

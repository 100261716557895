import {HelperConstant} from './helper.constant';

export class FileConstant {
  static readonly UPLOAD_AUDIO_TYPE = {MP3: 'mp3', OGG: 'ogg', WAV: 'wav'} as const;

  static readonly UPLOAD_ENTITY = {ALARM: HelperConstant.ENTITY.ALARM, USER: HelperConstant.ENTITY.USER} as const;

  static readonly UPLOAD_FIELD = {AVATAR: 'avatar', SOUND: 'sound'} as const;

  static readonly UPLOAD_FILE_SIZE_MAX = 5; // Megabytes

  static readonly UPLOAD_IMAGE_TYPE = {JPEG: 'jpeg', JPG: 'jpg', PNG: 'png'} as const;
}

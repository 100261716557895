<div class="modal-body">
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="row align-items-start">
        <div class="col-4">Tên đăng nhập</div>
        <div class="col-8">{{ user.username }}</div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row align-items-start">
        <div class="col-4">Tên người dùng</div>
        <div class="col-8">{{ user.fullName }}</div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row align-items-start">
        <div class="col-4">Ngày tạo</div>
        <div class="col-8">{{ user.createdAt | date: 'dd/MM/yyyy' }}</div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row align-items-start">
        <div class="col-4">Đơn vị</div>
        <div class="col-8">{{ user.company.name || '' }}</div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row align-items-start">
        <div class="col-4">Khu vực quản lý</div>
        <div class="col-8">
          <div *ngIf="!user.company.isSystemOwner; else elseArea">
            <li *ngFor="let area of user.areas">{{ area.name }}</li>
          </div>
          <ng-template #elseArea>Tất cả</ng-template>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row align-items-start">
        <div class="col-4">Vai trò</div>
        <div class="col-8">{{ user.role.name | roleName }}</div>
      </div>
    </li>
  </ul>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="onClose()">Đóng</button>
  <button
    *ngIf="permissionsUser?.['update'] && user.interaction.updatable"
    type="button"
    class="btn btn-outline-success"
    (click)="onOpenModalUserUpdate()"
  >
    <i class="bi bi-pencil-square"></i>&nbsp;Chỉnh sửa
  </button>
</div>

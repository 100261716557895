import {FileConstant} from '@rcms/constants';
import {TFileUploadAudioType, TFileUploadImageType} from '@rcms/types';

export class FileUtil {
  static getUploadAudioTypeValues(): TFileUploadAudioType[] {
    return Object.values(FileConstant.UPLOAD_AUDIO_TYPE);
  }

  static getUploadImageTypeValues(): TFileUploadImageType[] {
    return Object.values(FileConstant.UPLOAD_IMAGE_TYPE);
  }

  static isAudio(type: string): boolean {
    return type.startsWith('audio');
  }

  static isExceedMaxSize(size: number): boolean {
    return size / 1024 / 1024 > 5;
  }

  static isImage(type: string): boolean {
    return type.startsWith('image');
  }

  static isValidFileAudio(file: File, acceptedExtensions: string[]): boolean {
    return FileUtil.isAudio(file.type) && acceptedExtensions.some((ext) => file.name.endsWith(ext));
  }

  static isValidFileImage(file: File, acceptedExtensions: string[]): boolean {
    return FileUtil.isImage(file.type) && acceptedExtensions.some((ext) => file.name.endsWith(ext));
  }
}

import {Component, inject, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {ApiConstant, CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {StationAddressPipe} from '@rcms/pipes';
import {StationService, AreaService, DeviceService} from '@rcms/services';
import {StationStore} from '@rcms/states/akita/stores';
import {ISelection, IStationResponse, IStationUpdateRequest, LoopBackFilters} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';
import {cloneDeep as _cloneDeep} from 'lodash-es';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

type TRequestParams = [string, IStationUpdateRequest];

@Component({
  selector: 'app-station-update-modal',
  templateUrl: './station-update-modal.component.html',
  styleUrls: ['./station-update-modal.component.scss'],
})
export class StationUpdateModalComponent extends BaseCUDModalComponent<IStationResponse, TRequestParams>
  implements OnInit {
  private areaService = inject(AreaService);
  private deviceService = inject(DeviceService);
  private stationService = inject(StationService);
  private stationStore = inject(StationStore);

  areaDatasets: ISelection[] = [];
  deviceDatasets: ISelection[] = [];
  formStation!: FormGroup;
  formTrainStation!: FormGroup;
  isGettingAreas = false;
  isGettingDevices = false;
  station!: IStationResponse;
  readonly deviceUnassigned: ISelection = {label: 'Không gán thiết bị', value: null};
  initDeviceSelected!: ISelection;
  initAreaSelected?: ISelection;

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.station = this.data;
    this.initAreaSelected = {label: this.station.area?.name, value: this.station.area?.id};
    this.initDeviceSelected =
      this.station.device ? {label: this.station.device.name, value: this.station.device.id} : this.deviceUnassigned;

    this.patchDataForm(this.station);
    this.getAreaDatasets();
    this.getDeviceDatasets();
  }

  doRequestObs(requestParams: TRequestParams) {
    return this.stationService.updateStationByIdObs(...requestParams);
  }

  focusInputAddress() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.ADDRESS_EXIST);
  }

  focusInputName() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.NAME_EXIST);
  }

  focusoutInputAddress() {
    if (this.station.isTrainStation) return;

    const formAddress = this.form.get(CommonConstant.FIELD.ADDRESS);
    const formName = this.form.get(CommonConstant.FIELD.NAME);
    if (formAddress?.value) {
      const name = new StationAddressPipe().transform(formAddress.value);
      formName?.setValue(name);
    } else {
      formName?.setValue('');
    }
  }

  getAreaDatasets(name?: string) {
    this.isGettingAreas = true;
    this.areaDatasets = [];

    const filters = new LoopBackFilters().setLimit(ApiConstant.LOOPBACK_FILTER.LIMIT_MAX_SIZE);
    if (name) {
      filters.setWhere({name: ApiUtil.generateLoopbackFilterLike(name)});
    }

    this.areaService.getAllAreasObs(filters.serialize()).subscribe({
      next: (areas) => {
        this.isGettingAreas = false;
        this.areaDatasets = areas.map(area => ({label: area.name, value: area.id}));
      },
      error: () => this.isGettingAreas = false,
    });
  }

  getDeviceDatasets(name?: string) {
    this.isGettingDevices = true;
    this.deviceDatasets = [];

    this.deviceDatasets.push(this.deviceUnassigned);

    const filters = new LoopBackFilters().setLimit(ApiConstant.LOOPBACK_FILTER.LIMIT_MAX_SIZE);
    const whereFilter = name ? {name: ApiUtil.generateLoopbackFilterLike(name)} : {};
    filters.setWhere({...whereFilter, hasStation: false});
    this.deviceService.getAllDevicesObs(filters.serialize()).subscribe({
      next: (devices) => {
        this.isGettingDevices = false;
        this.deviceDatasets.push(...devices.map(device => ({label: device.name, value: device.id})));
      },
      error: () => this.isGettingDevices = false,
    });
  }

  getRequestParams(): TRequestParams {
    return [this.station.id, this.form.value];
  }

  handleRequestFailedSpecial() {
    this.setFeedbackUpdateFailed(ModalConstant.ENTITY.STATION);
  }

  handleRequestSuccessSpecial(station: IStationResponse) {
    this.stationStore.update(station.id, station);
    this.setFeedbackUpdateSuccess(ModalConstant.ENTITY.STATION);
  }

  initForm() {
    this.formStation = this.fb.group({
      [CommonConstant.FIELD.AREA_ID]: ['', [Validators.required]],
      [CommonConstant.FIELD.DEVICE_ID]: [null],
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256)]],
      [CommonConstant.FIELD.ADDRESS]: ['', [Validators.min(0), Validators.required]],
    });
    this.formTrainStation = this.fb.group({
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      [CommonConstant.FIELD.ADDRESS]: ['', [Validators.min(0), Validators.required]],
    });
  }

  onChangeAreaId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.AREA_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.AREA_NOT_FOUND);
  }

  onChangeDeviceId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.DEVICE_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.DEVICE_NOT_FOUND, ErrorConstant.ERROR_FLAG.DEVICE_USED);
  }

  patchDataForm(data: IStationResponse) {
    this.formStation.patchValue({areaId: data.areaId, deviceId: data.deviceId, name: data.name, address: data.address});
    this.formTrainStation.patchValue({name: data.name, address: data.address});
    this.form = data.isTrainStation ? _cloneDeep(this.formTrainStation) : _cloneDeep(this.formStation);
    this.onFormChangeValues();
  }
}

import {Component, inject, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {ApiConstant, CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {AreaService, CompanyService} from '@rcms/services';
import {AreaStore} from '@rcms/states/akita/stores';
import {IAreaResponse, IAreaUpdateRequest, ISelection, LoopBackFilters} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

type TRequestParams = [string, IAreaUpdateRequest];

@Component({
  selector: 'app-area-update-modal',
  templateUrl: './area-update-modal.component.html',
  styleUrls: ['./area-update-modal.component.scss'],
})
export class AreaUpdateModalComponent extends BaseCUDModalComponent<IAreaResponse, TRequestParams> implements OnInit {
  private areaService = inject(AreaService);
  private areaStore = inject(AreaStore);
  private companyService = inject(CompanyService);

  area!: IAreaResponse;
  companyMaintenanceDatasets: ISelection[] = [];
  companyObserverDatasets: ISelection[] = [];
  isGettingMaintenanceCompanies = false;
  isGettingObserverCompanies = false;

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.area = this.data;
    this.patchDataForm(this.area);
    this.getCompanyMaintenanceDatasets();
    this.getCompanyObserverDatasets();
  }

  doRequestObs(requestParams: TRequestParams) {
    return this.areaService.updateAreaByIdObs(...requestParams);
  }

  focusInputName() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.NAME_EXIST);
  }

  getCompanyMaintenanceDatasets(name?: string) {
    this.isGettingMaintenanceCompanies = true;
    this.companyMaintenanceDatasets = [];

    const filters = new LoopBackFilters().setLimit(ApiConstant.LOOPBACK_FILTER.LIMIT_MAX_SIZE);
    if (name) {
      filters.setWhere({name: ApiUtil.generateLoopbackFilterLike(name)});
    }
    this.companyService.getAllCompaniesObs(filters.serialize()).subscribe({
      next: (companies) => {
        this.isGettingMaintenanceCompanies = false;
        this.companyMaintenanceDatasets = companies.map(company => ({label: company.name, value: company.id}));
      },
      error: () => this.isGettingMaintenanceCompanies = false,
    });
  }

  getCompanyObserverDatasets(name?: string) {
    this.isGettingObserverCompanies = true;
    this.companyObserverDatasets = [];

    const filters = new LoopBackFilters().setLimit(ApiConstant.LOOPBACK_FILTER.LIMIT_MAX_SIZE);
    if (name) {
      filters.setWhere({name: ApiUtil.generateLoopbackFilterLike(name)});
    }
    this.companyService.getAllCompaniesObs(filters.serialize()).subscribe({
      next: (companies) => {
        this.isGettingObserverCompanies = false;
        this.companyObserverDatasets = companies.map(company => ({label: company.name, value: company.id}));
      },
      error: () => this.isGettingObserverCompanies = false,
    });
  }

  getRequestParams(): TRequestParams {
    return [this.area.id, this.form.value];
  }

  handleRequestFailedSpecial() {
    this.setFeedbackUpdateFailed(ModalConstant.ENTITY.AREA);
  }

  handleRequestSuccessSpecial(area: IAreaResponse) {
    this.areaStore.update(area.id, area);
    this.setFeedbackUpdateSuccess(ModalConstant.ENTITY.AREA);
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      [CommonConstant.FIELD.COMPANY_MAINTENANCE_ID]: ['', [Validators.required]],
      [CommonConstant.FIELD.COMPANY_OBSERVER_ID]: ['', [Validators.required]],
    });
  }

  onChangeCompanyMaintenanceId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.COMPANY_MAINTENANCE_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.COMPANY_MAINTENANCE_NOT_FOUND);
  }

  onChangeCompanyObserverId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.COMPANY_OBSERVER_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.COMPANY_OBSERVER_NOT_FOUND);
  }

  patchDataForm(data: IAreaResponse) {
    this.form.patchValue({
      name: data.name,
      companyMaintenanceId: data.companyMaintenanceId,
      companyObserverId: data.companyObserverId,
    });
    this.onFormChangeValues();
  }
}

import {Component, Input, signal} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CommonConstant} from '@rcms/constants';
import {IFeedback, IFeedbackOptions, IModal, TErrorFlag, TModalEntity} from '@rcms/types';
import {CommonUtil} from '@rcms/utils';

@Component({selector: 'app-base-modal', template: ''})
export abstract class BaseModalComponent<TModalData> implements IModal<TModalData> {
  @Input() data!: TModalData;
  @Input() modalRef!: NgbModalRef;

  feedbackErrors: Partial<Record<TErrorFlag, boolean>> = {};
  hasChange = false;
  isInvalid = false;
  isLoading = false;

  feedback = signal<IFeedback | null>(null);

  onClose() {
    this.modalRef.close();
  }

  clearFeedback(): void {
    this.setFeedback(null);
  }

  clearFeedbackErrors(...errorFlags: TErrorFlag[]) {
    if (errorFlags.length === 0) {
      this.feedbackErrors = {};
      return;
    }

    errorFlags.forEach((errorFlag) => {
      delete this.feedbackErrors[errorFlag];
    });
  }

  setFeedbackCreateFailed(entity?: TModalEntity, messageSuffix?: string, options?: IFeedbackOptions) {
    this.setFeedback({
      state: CommonConstant.FEEDBACK.STATE.ERROR,
      message: CommonUtil.getFeedbackMessageFailed(CommonConstant.FEEDBACK.ACTION.CREATE, entity, messageSuffix),
      options: options ?? {btnBackVisible: true},
    });
  }

  setFeedbackCreateSuccess(entity?: TModalEntity, messageSuffix?: string, options?: IFeedbackOptions) {
    this.setFeedback({
      state: CommonConstant.FEEDBACK.STATE.SUCCESS,
      message: CommonUtil.getFeedbackMessageSuccess(CommonConstant.FEEDBACK.ACTION.CREATE, entity, messageSuffix),
      options: options,
    });
  }

  setFeedbackDeleteFailed(entity?: TModalEntity, messageSuffix?: string, options?: IFeedbackOptions) {
    this.setFeedback({
      state: CommonConstant.FEEDBACK.STATE.ERROR,
      message: CommonUtil.getFeedbackMessageFailed(CommonConstant.FEEDBACK.ACTION.DELETE, entity, messageSuffix),
      options: options ?? {btnBackVisible: true},
    });
  }

  setFeedbackDeleteSuccess(entity?: TModalEntity, messageSuffix?: string, options?: IFeedbackOptions) {
    this.setFeedback({
      state: CommonConstant.FEEDBACK.STATE.SUCCESS,
      message: CommonUtil.getFeedbackMessageSuccess(CommonConstant.FEEDBACK.ACTION.DELETE, entity, messageSuffix),
      options: options,
    });
  }

  setFeedbackError(errorFlag: TErrorFlag) {
    this.feedbackErrors[errorFlag] = true;
  }

  setFeedbackUpdateFailed(entity?: TModalEntity, messageSuffix?: string, options?: IFeedbackOptions) {
    this.setFeedback({
      state: CommonConstant.FEEDBACK.STATE.ERROR,
      message: CommonUtil.getFeedbackMessageFailed(CommonConstant.FEEDBACK.ACTION.UPDATE, entity, messageSuffix),
      options: options ?? {btnBackVisible: true},
    });
  }

  setFeedbackUpdateSuccess(entity?: TModalEntity, messageSuffix?: string, options?: IFeedbackOptions) {
    this.setFeedback({
      state: CommonConstant.FEEDBACK.STATE.SUCCESS,
      message: CommonUtil.getFeedbackMessageSuccess(CommonConstant.FEEDBACK.ACTION.UPDATE, entity, messageSuffix),
      options: options,
    });
  }

  private setFeedback(feedback: IFeedback | null) {
    this.feedback.set(feedback);
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {TRoleName} from '@rcms/types';
import {RoleUtil} from '@rcms/utils';

@Pipe({name: 'roleName', standalone: true})
export class RoleNamePipe implements PipeTransform {
  transform(value: TRoleName): string {
    const roleNameDisplay = RoleUtil.getRoleNameDisplay(value);
    return roleNameDisplay ?? value;
  }
}

import {Component, inject, OnInit} from '@angular/core';
import {ModalConstant} from '@rcms/constants';
import {DeviceService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {IDeviceResponse} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-device-delete-modal',
  templateUrl: './device-delete-modal.component.html',
  styleUrls: ['./device-delete-modal.component.scss'],
})
export class DeviceDeleteModalComponent extends BaseCUDModalComponent<IDeviceResponse, string> implements OnInit {
  private deviceService = inject(DeviceService);

  device!: IDeviceResponse;

  ngOnInit() {
    this.device = this.data;
  }

  doRequestObs(deviceId: string) {
    return this.deviceService.deleteDeviceByIdObs(deviceId);
  }

  getRequestParams(): string {
    return this.device.id;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackDeleteFailed(ModalConstant.ENTITY.DEVICE);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackDeleteSuccess(ModalConstant.ENTITY.DEVICE);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.DEVICE_DELETE);
  }
}

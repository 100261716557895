import {Component, DestroyRef, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormGroup, NonNullableFormBuilder} from '@angular/forms';
import {IErrorResponse} from '@rcms/types';
import {CommonUtil} from '@rcms/utils';
import {isEmpty as _isEmpty, isEqual as _isEqual} from 'lodash-es';
import {Observable} from 'rxjs';
import {BaseModalComponent} from './base-modal.component';

@Component({selector: 'app-base-cud-modal', template: ''})
export abstract class BaseCUDModalComponent<TModalData, TRequestParams> extends BaseModalComponent<TModalData> {
  protected readonly destroyRef = inject(DestroyRef);
  protected fb = inject(NonNullableFormBuilder);

  form!: FormGroup;

  abstract doRequestObs(requestParams: TRequestParams): Observable<TModalData | void>;

  abstract getRequestParams(): TRequestParams;

  handleRequestFailed(error?: IErrorResponse) {
    this.isLoading = false;
    if (error?.details?.length) {
      const errorFlag = CommonUtil.getErrorFlagByCodeAndDetail(error.code, error.details[0]);
      if (errorFlag) {
        this.setFeedbackError(errorFlag);
        return;
      }
    }

    this.handleRequestFailedSpecial(error);
  }

  abstract handleRequestFailedSpecial(error?: IErrorResponse): void;

  handleRequestSuccess(data: TModalData | void) {
    this.isLoading = false;
    this.handleRequestSuccessSpecial(data);
  }

  abstract handleRequestSuccessSpecial(data: TModalData | void): void;

  onDelete() {
    this.isLoading = true;
    this.doRequestObs(this.getRequestParams()).subscribe({
      next: () => this.handleRequestSuccess(),
      error: (error) => this.handleRequestFailed(error),
    });
  }

  onFormChangeValues() {
    const initialValue = this.form.value;
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(formWithChanges => this.hasChange = !_isEqual(initialValue, formWithChanges));
  }

  onBack() {
    this.clearFeedback();
  }

  onSave() {
    this.onSaveSpecial ? this.onSaveSpecial() : this.onSaveCommon();
  }

  onSaveSpecial?(): void;

  onSaveCommon() {
    this.isInvalid = this.form.invalid || !_isEmpty(this.feedbackErrors);
    if (this.hasChange && !this.isInvalid) {
      this.isLoading = true;
      this.doRequestObs(this.getRequestParams()).subscribe({
        next: (data) => this.handleRequestSuccess(data),
        error: (error) => this.handleRequestFailed(error),
      });
    }
  }
}

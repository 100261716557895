import {Component, inject} from '@angular/core';
import {Validators} from '@angular/forms';
import {CommonConstant, ModalConstant, RouteConstant, UserConstant} from '@rcms/constants';
import {FormValidatorService, NavigationService, StateService, UserService} from '@rcms/services';
import {IUserUpdateMyPasswordRequest} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-user-update-my-password-modal',
  templateUrl: './user-update-my-password-modal.component.html',
  styleUrls: ['./user-update-my-password-modal.component.scss'],
})
export class UserUpdateMyPasswordModalComponent extends BaseCUDModalComponent<null, IUserUpdateMyPasswordRequest> {
  private navigationService = inject(NavigationService);
  private userService = inject(UserService);

  constructor() {
    super();
    this.initForm();
  }

  doRequestObs(requestBody: IUserUpdateMyPasswordRequest) {
    return this.userService.updateUserMyPasswordObs(requestBody);
  }

  getRequestParams(): IUserUpdateMyPasswordRequest {
    return {passwordNew: this.form.value.passwordNew, passwordOld: this.form.value.passwordOld};
  }

  handleRequestFailedSpecial() {
    this.setFeedbackUpdateFailed(ModalConstant.ENTITY.PASSWORD);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackUpdateSuccess(
      ModalConstant.ENTITY.PASSWORD,
      'Vui lòng đăng nhập lại để tiếp tục sử dụng dịch vụ.',
      {btnAcceptVisible: true, btnCloseVisible: false},
    );
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.PASSWORD]: [
        '',
        [
          Validators.minLength(UserConstant.PASSWORD.LENGTH.MIN),
          Validators.maxLength(UserConstant.PASSWORD.LENGTH.MAX),
          Validators.required,
        ],
      ],
      [CommonConstant.FIELD.PASSWORD_NEW]: [
        '',
        [
          Validators.minLength(UserConstant.PASSWORD.LENGTH.MIN),
          Validators.maxLength(UserConstant.PASSWORD.LENGTH.MAX),
          Validators.required,
        ],
      ],
      [CommonConstant.FIELD.PASSWORD_NEW_CONFIRM]: [
        '',
        [
          Validators.minLength(UserConstant.PASSWORD.LENGTH.MIN),
          Validators.maxLength(UserConstant.PASSWORD.LENGTH.MAX),
          Validators.required,
        ],
      ],
    }, {validators: [FormValidatorService.differentPasswordValidation, FormValidatorService.samePasswordValidation]});
  }

  onAccept() {
    this.onClose();
    this.navigationService.navigateToAsync(RouteConstant.ROUTE_NAME.LOGIN).then(() => StateService.clearAll());
  }
}

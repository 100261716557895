import {Component, inject, OnInit} from '@angular/core';
import {ModalConstant} from '@rcms/constants';
import {TrainStationService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {ITrainStationResponse} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-train-station-delete-modal',
  templateUrl: './train-station-delete-modal.component.html',
  styleUrls: ['./train-station-delete-modal.component.scss'],
})
export class TrainStationDeleteModalComponent extends BaseCUDModalComponent<ITrainStationResponse, string>
  implements OnInit {
  private trainStationService = inject(TrainStationService);

  trainStation!: ITrainStationResponse;

  ngOnInit() {
    this.trainStation = this.data;
  }

  doRequestObs(trainStationId: string) {
    return this.trainStationService.deleteTrainStationByIdObs(trainStationId);
  }

  getRequestParams(): string {
    return this.trainStation.id;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackDeleteFailed(ModalConstant.ENTITY.TRAIN_STATION);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackDeleteSuccess(ModalConstant.ENTITY.TRAIN_STATION);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.TRAIN_STATION_DELETE);
  }
}

import {Pipe, PipeTransform} from '@angular/core';
import {CommonUtil} from '@rcms/utils';

@Pipe({name: 'userPassword', standalone: true})
export class UserPasswordPipe implements PipeTransform {
  transform(value: string, userId: string): string {
    if (value && userId) {
      return CommonUtil.aesDecrypt(value, userId);
    }
    return '';
  }
}

export class PermissionConstant {
  static readonly ACTION = {
    CREATE: 'create',
    DELETE: 'delete',
    READ: 'read',
    READ_EVENT: 'read_event',
    READ_SIMULATION: 'read_simulation',
    READ_SPECIFICATION: 'read_specification',
    UPDATE: 'update',
  } as const;
}

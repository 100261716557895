import {inject, Injectable} from '@angular/core';
import {DeviceApi} from '@rcms/apis';
import {ICountResponse, IDeviceResponse, IDeviceUpdateRequest, ILoopBackFilters} from '@rcms/types';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class DeviceService {
  private deviceApi = inject(DeviceApi);

  deleteDeviceByIdObs(deviceId: string): Observable<void> {
    return this.deviceApi.deleteDeviceById(deviceId);
  }

  getAllDevicesObs(filters: ILoopBackFilters): Observable<IDeviceResponse[]> {
    return this.deviceApi.getAllDevices(filters);
  }

  getCountAllDevicesObs(where: object): Observable<ICountResponse> {
    return this.deviceApi.getCountAllDevices(where);
  }

  updateDeviceByIdObs(deviceId: string, requestBody: IDeviceUpdateRequest): Observable<IDeviceResponse> {
    return this.deviceApi.updateDeviceById(deviceId, requestBody);
  }
}

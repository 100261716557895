import {CommonConstant} from '@rcms/constants';

export interface ICountResponse {
  count: number;
}

export interface ICreatedAndModifiedResponse {
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
}

export interface IEntityInteraction {
  deletable: boolean;
  updatable: boolean;
}

export interface IFeedback {
  state: TFeedbackState;
  message: string;
  options?: IFeedbackOptions;
}

export interface IFeedbackOptions {
  btnAcceptText?: string;
  btnAcceptVisible?: boolean;
  btnAcceptStyleClass?: string;
  btnBackText?: string;
  btnBackVisible?: boolean;
  btnBackStyleClass?: string;
  btnCloseText?: string;
  btnCloseVisible?: boolean;
  btnCloseStyleClass?: string;
}

export interface IPagingData {
  lengthOfList: number;
  totalItems: number;
}

export interface ISelection {
  label: TOptional<string>;
  value: unknown;
}

export type TEntity = TObjectValues<typeof CommonConstant.ENTITY>;
export type TEntityIsUsedByOther = Partial<Record<TEntity, boolean>>;
export type TFeedbackAction = TObjectValues<typeof CommonConstant.FEEDBACK.ACTION>;
export type TFeedbackState = TObjectValues<typeof CommonConstant.FEEDBACK.STATE>;
export type TObjectValues<T> = T[keyof T];
export type TOptional<T> = T | undefined;

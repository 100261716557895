<div class="modal-body">
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="row align-items-start">
        <div class="col-4">Tên vai trò</div>
        <div class="col-8">{{ role.name | roleName }}</div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row align-items-start">
        <div class="col-4">Mô tả</div>
        <div class="col-8">{{ role.description }}</div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row align-items-start">
        <div class="col-12">Quyền hạn</div>
        <div class="col-12">
          <div class="pt-2 pl-4 w-100">
            <ngx-treeview class="w-100" [config]="treeConfig" [items]="permissionTree"></ngx-treeview>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="onClose()">Đóng</button>
</div>

import {Component, inject, OnInit} from '@angular/core';
import {ModalConstant} from '@rcms/constants';
import {AlarmService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {IAlarmResponse} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-alarm-delete-modal',
  templateUrl: './alarm-delete-modal.component.html',
  styleUrls: ['./alarm-delete-modal.component.scss'],
})
export class AlarmDeleteModalComponent extends BaseCUDModalComponent<IAlarmResponse, string> implements OnInit {
  private alarmService = inject(AlarmService);

  alarm!: IAlarmResponse;

  ngOnInit() {
    this.alarm = this.data;
  }

  doRequestObs(alarmId: string) {
    return this.alarmService.deleteAlarmByIdObs(alarmId);
  }

  getRequestParams(): string {
    return this.alarm.id;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackDeleteFailed(ModalConstant.ENTITY.ALARM);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackDeleteSuccess(ModalConstant.ENTITY.ALARM);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.ALARM_DELETE);
  }
}

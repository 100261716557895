import {Pipe, PipeTransform} from '@angular/core';
import {ApiConstant} from '@rcms/constants';
import {CommonUtil} from '@rcms/utils';

@Pipe({name: 'orderNumbers', standalone: true})
export class OrderNumbersPipe implements PipeTransform {
  transform(value: number, props: {page: number, limit?: number}): number {
    const previousPage = CommonUtil.getPreviousPage(props.page);
    const itemsSize = props.limit ?? ApiConstant.LOOPBACK_FILTER.LIMIT_DEFAULT;

    return (previousPage * itemsSize) + value + 1;
  }
}

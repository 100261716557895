import {ApiConstant} from '@rcms/constants';
import {CommonUtil} from '@rcms/utils';

export interface ILoopBackFilters {
  fields?: object;
  limit?: number;
  order?: string | string[];
  skip?: number;
  where?: object;
}

export class LoopBackFilters {
  private limit = ApiConstant.LOOPBACK_FILTER.LIMIT_DEFAULT;
  private order = 'createdAt DESC';
  private skip = ApiConstant.LOOPBACK_FILTER.SKIP_DEFAULT;
  private where = {};

  getLimit() {
    return this.limit;
  }

  setLimit(value: number) {
    this.limit = value;
    return this;
  }

  getOrder() {
    return this.order;
  }

  setOrder(value: string): LoopBackFilters {
    this.order = value;
    return this;
  }

  getSkip() {
    return this.skip;
  }

  setSkip(value: number): LoopBackFilters {
    this.skip = value;
    return this;
  }

  setSkipFromPage(page: number) {
    const previousPage = CommonUtil.getPreviousPage(page);
    this.setSkip(previousPage * this.limit);
  }

  getWhere() {
    return this.where;
  }

  setWhere(value: object): LoopBackFilters {
    this.where = value;
    return this;
  }

  serialize(): ILoopBackFilters {
    return {
      limit: this.limit,
      order: this.order,
      skip: this.skip,
      where: this.where,
    };
  }
}

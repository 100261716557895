<ng-container *ngIf="!feedback()">
  <div class="modal-body">
    <form class="mx-3 form-group" [formGroup]="form" (ngSubmit)="onSave()">
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputName" class="form-label">Tên nhà ga</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          type="text"
          class="form-control"
          id="inputName"
          placeholder="Nhập tên"
          formControlName="name"
          [ngClass]="{
            'is-invalid':
              ((isInvalid || form.get('name')?.touched) && form.get('name')?.invalid) || feedbackErrors['nameExist'],
          }"
          (focus)="focusInputName()"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('name')?.errors?.['maxlength']">Độ dài quá 256 ký tự</p>
          <p *ngIf="form.get('name')?.errors?.['required']">Tên không được để trống</p>
          <p *ngIf="feedbackErrors['nameExist']">Tên đã tồn tại</p>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputAddress" class="form-label">Địa chỉ</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <textarea
          rows="3"
          class="form-control"
          id="inputAddress"
          placeholder="Nhập địa chỉ"
          formControlName="address"
          [ngClass]="{'is-invalid': (isInvalid || form.get('address')?.touched) && form.get('address')?.invalid}"
        >
        </textarea>
        <div class="invalid-feedback">
          <p *ngIf="form.get('address')?.errors?.['maxlength']">Độ dài quá 512 ký tự</p>
          <p *ngIf="form.get('address')?.errors?.['required']">Địa chỉ không được để trống</p>
        </div>
      </div>
    </form>
  </div>
  <app-footer-modal
    [buttonSubmitDisabled]="!hasChange"
    [isLoading]="isLoading"
    (eventCancel)="onClose()"
    (eventSubmit)="onSave()"
  ></app-footer-modal>
</ng-container>
<app-feedback-modal [feedback]="feedback()" (eventBack)="onBack()" (eventClose)="onClose()"></app-feedback-modal>

<ng-container *ngIf="!feedback()">
  <div class="modal-body">
    <form class="mx-3 form-group" [formGroup]="form" (ngSubmit)="onSave()">
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputName" class="form-label">Tên khu vực</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          type="text"
          class="form-control"
          id="inputName"
          placeholder="Nhập tên"
          formControlName="name"
          [ngClass]="{
            'is-invalid':
              ((isInvalid || form.get('name')?.touched) && form.get('name')?.invalid) || feedbackErrors['nameExist'],
          }"
          (focus)="focusInputName()"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('name')?.errors?.['maxlength']">Độ dài quá 256 ký tự</p>
          <p *ngIf="form.get('name')?.errors?.['required']">Tên không được để trống</p>
          <p *ngIf="feedbackErrors['nameExist']">Tên đã tồn tại</p>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <p class="form-label">Xí nghiệp quản lý</p>
          <p class="mx-1 text-danger">*</p>
        </div>
        <div class="w-100">
          <app-selection-single
            [datasets]="companyMaintenanceDatasets"
            [hasInvalid]="
              ((isInvalid || form.get('companyMaintenanceId')?.touched) && form.get('companyMaintenanceId')?.invalid) ||
              feedbackErrors['companyMaintenanceNotFound']
            "
            [hasSearch]="true"
            [isGettingDatasets]="isGettingMaintenanceCompanies"
            [ngClass]="{
              'is-invalid':
                ((isInvalid || form.get('companyMaintenanceId')?.touched) &&
                  form.get('companyMaintenanceId')?.invalid) ||
                feedbackErrors['companyMaintenanceNotFound'],
            }"
            (changeSelectionEvent)="onChangeCompanyMaintenanceId($event)"
            (searchValueEvent)="getCompanyMaintenanceDatasets($event)"
          ></app-selection-single>
          <div class="invalid-feedback">
            <p *ngIf="form.get('companyMaintenanceId')?.errors?.['required']">Chọn một đơn vị</p>
            <p *ngIf="feedbackErrors['companyMaintenanceNotFound']">Đơn vị không còn tồn tại</p>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <p class="form-label">Đơn vị cầu đường</p>
          <p class="mx-1 text-danger">*</p>
        </div>
        <div class="w-100">
          <app-selection-single
            [datasets]="companyObserverDatasets"
            [hasInvalid]="
              ((isInvalid || form.get('companyObserverId')?.touched) && form.get('companyObserverId')?.invalid) ||
              feedbackErrors['companyObserverNotFound']
            "
            [hasSearch]="true"
            [isGettingDatasets]="isGettingMaintenanceCompanies"
            [ngClass]="{
              'is-invalid':
                ((isInvalid || form.get('companyObserverId')?.touched) && form.get('companyObserverId')?.invalid) ||
                feedbackErrors['companyObserverNotFound'],
            }"
            (changeSelectionEvent)="onChangeCompanyObserverId($event)"
            (searchValueEvent)="getCompanyObserverDatasets($event)"
          ></app-selection-single>
          <div class="invalid-feedback">
            <p *ngIf="form.get('companyObserverId')?.errors?.['required']">Chọn một đơn vị</p>
            <p *ngIf="feedbackErrors['companyObserverNotFound']">Đơn vị không còn tồn tại</p>
          </div>
        </div>
      </div>
    </form>
  </div>
  <app-footer-modal
    [buttonSubmitDisabled]="!hasChange"
    [isLoading]="isLoading"
    (eventCancel)="onClose()"
    (eventSubmit)="onSave()"
  ></app-footer-modal>
</ng-container>
<app-feedback-modal [feedback]="feedback()" (eventBack)="onBack()" (eventClose)="onClose()"></app-feedback-modal>

import {inject, Injectable} from '@angular/core';
import {AlarmApi} from '@rcms/apis';
import {IAlarmCreateRequest, IAlarmResponse, IAlarmUpdateRequest, ICountResponse, ILoopBackFilters} from '@rcms/types';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AlarmService {
  private alarmApi = inject(AlarmApi);

  createAlarmObs(requestBody: IAlarmCreateRequest): Observable<IAlarmResponse> {
    return this.alarmApi.createAlarm(requestBody);
  }

  deleteAlarmByIdObs(alarmId: string): Observable<void> {
    return this.alarmApi.deleteAlarmById(alarmId);
  }

  getAllAlarmsObs(filters: ILoopBackFilters): Observable<IAlarmResponse[]> {
    return this.alarmApi.getAllAlarms(filters);
  }

  getCountAllAlarmsObs(where: object): Observable<ICountResponse> {
    return this.alarmApi.getCountAllAlarms(where);
  }

  updateAlarmByIdObs(alarmId: string, requestBody: IAlarmUpdateRequest): Observable<IAlarmResponse> {
    return this.alarmApi.updateAlarmById(alarmId, requestBody);
  }
}

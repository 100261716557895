import {Component, inject} from '@angular/core';
import {Validators} from '@angular/forms';
import {CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {CompanyService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {ICompanyCreateRequest, ICompanyResponse} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-company-create-modal',
  templateUrl: './company-create-modal.component.html',
  styleUrls: ['./company-create-modal.component.scss'],
})
export class CompanyCreateModalComponent extends BaseCUDModalComponent<ICompanyResponse, ICompanyCreateRequest> {
  private companyService = inject(CompanyService);

  constructor() {
    super();
    this.initForm();
  }

  doRequestObs(requestBody: ICompanyCreateRequest) {
    return this.companyService.createCompanyObs(requestBody);
  }

  focusInputName() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.NAME_EXIST);
  }

  getRequestParams(): ICompanyCreateRequest {
    return this.form.value;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackCreateFailed(ModalConstant.ENTITY.COMPANY);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackCreateSuccess(ModalConstant.ENTITY.COMPANY);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.COMPANY_CREATE);
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      [CommonConstant.FIELD.DESCRIPTION]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_512)]],
    });
    this.onFormChangeValues();
  }
}

import {IAlarmResponse} from './alarm.type';
import {ICreatedAndModifiedResponse, IEntityInteraction} from './common.type';

export interface IStationEventResponse extends ICreatedAndModifiedResponse {
  id: string;
  alarmId: string | null;
  code: number;
  description: string;
  interaction: IEntityInteraction;
  name: string;
  stationEventResetId: string | null;
  alarm: IAlarmResponse | null;
  stationEventReset: IStationEventResponse | null;
}

export interface IStationEventCreateRequest {
  alarmId: string | null;
  stationEventResetId: string | null;
  name: string;
  description: string;
  code: number;
}

export interface IStationEventUpdateRequest {
  alarmId?: string | null;
  stationEventResetId?: string | null;
  name?: string;
  description?: string;
  code?: number;
}

import {IAreaResponse} from '@rcms/types/area.type';
import {ICreatedAndModifiedResponse, IEntityInteraction} from '@rcms/types/common.type';
import {ICompanyResponse} from '@rcms/types/company.type';
import {IFileStorageResponse} from '@rcms/types/file.type';
import {IRoleResponse} from '@rcms/types/role.type';

interface IUserPreferences {
  language: string;
  theme: string;
}

export interface IUserResponse extends ICreatedAndModifiedResponse {
  id: string;
  companyId: string;
  roleId: string;
  active: boolean;
  avatar: IFileStorageResponse | null;
  fullName: string;
  interaction: IEntityInteraction;
  password: string;
  preferences: IUserPreferences;
  username: string;
  areas: IAreaResponse[];
  company: ICompanyResponse;
  role: IRoleResponse;
}

export interface IUserCreateRequest {
  active: boolean;
  companyId: string;
  fullName: string;
  password: string;
  roleId: string;
  username: string;
}

export interface IUserLoginRequest {
  username: string;
  password: string;
}

export interface IUserLoginResponse {
  createdAt: string;
  ttl: number;
  token: string;
}

export interface IUserLogoutRequest {
  token: string;
}

export interface IUserUpdateRequest {
  active?: boolean;
  fullName?: string;
  password?: string;
  roleId?: string;
  username?: string;
}

export interface IUserUpdateMyPasswordRequest {
  passwordOld: string;
  passwordNew: string;
}

export interface IUserUpdateMyProfileRequest {
  fullName?: string;
  avatarOriginalName?: string;
}

import {RouteConstant} from '@rcms/constants';
import {TRouteName} from '@rcms/types';

const routesPath: Partial<Record<TRouteName, string>> = {
  [RouteConstant.ROUTE_NAME.DASHBOARD]: RouteConstant.ROUTE_NAME.DASHBOARD,
  [RouteConstant.ROUTE_NAME.DATA_MANAGEMENT]: RouteConstant.ROUTE_NAME.DATA_MANAGEMENT,
  [RouteConstant.ROUTE_NAME.FORBIDDEN]: RouteConstant.ROUTE_NAME.FORBIDDEN,
  [RouteConstant.ROUTE_NAME.HOME]: RouteConstant.ROUTE_NAME.HOME,
  [RouteConstant.ROUTE_NAME.LOGIN]: `${RouteConstant.ROUTE_NAME.AUTH}/${RouteConstant.ROUTE_NAME.LOGIN}`,
  [RouteConstant.ROUTE_NAME.LOGOUT]: `${RouteConstant.ROUTE_NAME.AUTH}/${RouteConstant.ROUTE_NAME.LOGOUT}`,
  [RouteConstant.ROUTE_NAME.MY_PROFILE]: RouteConstant.ROUTE_NAME.MY_PROFILE,
  [RouteConstant.ROUTE_NAME.NOT_FOUND]: RouteConstant.ROUTE_NAME.NOT_FOUND,
  [RouteConstant.ROUTE_NAME.STATIONS_MANAGEMENT]: RouteConstant.ROUTE_NAME.STATIONS_MANAGEMENT,
  [RouteConstant.ROUTE_NAME.SYSTEM_SETTINGS]: RouteConstant.ROUTE_NAME.SYSTEM_SETTINGS,
  [RouteConstant.ROUTE_NAME.USERS_MANAGEMENT]: RouteConstant.ROUTE_NAME.USERS_MANAGEMENT,
};

export class RouteUtil {
  static getPathByRouteName(routeName: TRouteName): string {
    return routesPath[routeName] ?? routeName;
  }
}

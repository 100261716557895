import {Component, inject, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {ApiConstant, CommonConstant, ErrorConstant, ModalConstant, StationEventConstant} from '@rcms/constants';
import {AlarmService, StationEventService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {ISelection, IStationEventCreateRequest, IStationEventResponse, LoopBackFilters} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-station-event-create-modal',
  templateUrl: './station-event-create-modal.component.html',
  styleUrls: ['./station-event-create-modal.component.scss'],
})
export class StationEventCreateModalComponent
  extends BaseCUDModalComponent<IStationEventResponse, IStationEventCreateRequest>
  implements OnInit {
  private alarmService = inject(AlarmService);
  private stationEventService = inject(StationEventService);

  alarmDatasets: ISelection[] = [];
  readonly alarmUnassigned: ISelection = {label: 'Không gán cảnh báo', value: null};
  isGettingAlarms = false;
  isGettingStationEventResets = false;
  stationEventResetsDatasets: ISelection[] = [];
  readonly stationEventResetUnassigned: ISelection = {label: 'Không gán sự kiện khôi phục', value: null};

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.getAlarmDatasets();
    this.getStationEventResetDatasets();
  }

  doRequestObs(requestBody: IStationEventCreateRequest) {
    return this.stationEventService.createStationEventObs(requestBody);
  }

  focusInputCode() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.CODE_EXIST);
  }

  focusInputName() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.NAME_EXIST);
  }

  getAlarmDatasets(name?: string) {
    this.isGettingAlarms = true;
    this.alarmDatasets = [];

    this.alarmDatasets.push(this.alarmUnassigned);

    const filters = new LoopBackFilters()
      .setLimit(ApiConstant.LOOPBACK_FILTER.LIMIT_MAX_SIZE)
      .setOrder(`${CommonConstant.FIELD.LEVEL} ASC`);

    if (name) {
      filters.setWhere({name: ApiUtil.generateLoopbackFilterLike(name)});
    }
    this.alarmService.getAllAlarmsObs(filters.serialize()).subscribe({
      next: (alarms) => {
        this.isGettingAlarms = false;
        this.alarmDatasets.push(...alarms.map(alarm => ({label: alarm.name, value: alarm.id})));
      },
      error: () => this.isGettingAlarms = false,
    });
  }

  getStationEventResetDatasets(name?: string) {
    this.isGettingStationEventResets = true;
    this.stationEventResetsDatasets = [];

    this.stationEventResetsDatasets.push(this.stationEventResetUnassigned);

    const filters = new LoopBackFilters()
      .setLimit(ApiConstant.LOOPBACK_FILTER.LIMIT_MAX_SIZE)
      .setOrder(`${CommonConstant.FIELD.CODE} ASC`);

    if (name) {
      filters.setWhere({name: ApiUtil.generateLoopbackFilterLike(name)});
    }
    this.stationEventService.getAllStationEventsObs(filters.serialize()).subscribe({
      next: (stationEvents) => {
        this.isGettingStationEventResets = false;
        this.stationEventResetsDatasets.push(
          ...stationEvents.map(stationEvent => ({label: stationEvent.name, value: stationEvent.id})),
        );
      },
      error: () => this.isGettingStationEventResets = false,
    });
  }

  getRequestParams(): IStationEventCreateRequest {
    return this.form.value;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackCreateFailed(ModalConstant.ENTITY.STATION_EVENT);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackCreateSuccess(ModalConstant.ENTITY.STATION_EVENT);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.STATION_EVENT_CREATE);
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.ALARM_ID]: [null],
      [CommonConstant.FIELD.STATION_EVENT_RESET_ID]: [null],
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      [CommonConstant.FIELD.DESCRIPTION]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_512)]],
      [CommonConstant.FIELD.CODE]: ['', [Validators.min(StationEventConstant.CODE.MIN), Validators.required]],
    });
    this.onFormChangeValues();
  }

  onChangeAlarmId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.ALARM_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.ALARM_NOT_FOUND);
  }

  onChangeStationEventResetId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.STATION_EVENT_RESET_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.STATION_EVENT_RESET_NOT_FOUND);
  }
}

import {Component, inject, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {CommonConstant, ErrorConstant, ModalConstant} from '@rcms/constants';
import {TrainStationService} from '@rcms/services';
import {TrainStationStore} from '@rcms/states/akita/stores';
import {ITrainStationResponse, ITrainStationUpdateRequest} from '@rcms/types';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-train-station-update-modal',
  templateUrl: './train-station-update-modal.component.html',
  styleUrls: ['./train-station-update-modal.component.scss'],
})
export class TrainStationUpdateModalComponent
  extends BaseCUDModalComponent<ITrainStationResponse, ITrainStationUpdateRequest>
  implements OnInit {
  private trainStationService = inject(TrainStationService);
  private trainStationStore = inject(TrainStationStore);

  trainStation!: ITrainStationResponse;

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.trainStation = this.data;
    this.patchDataForm(this.trainStation);
  }

  doRequestObs(requestParams: ITrainStationUpdateRequest) {
    return this.trainStationService.updateTrainStationByIdObs(this.trainStation.id, requestParams);
  }

  focusInputName() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.NAME_EXIST);
  }

  getRequestParams(): ITrainStationUpdateRequest {
    return this.form.value;
  }

  handleRequestFailedSpecial() {
    this.setFeedbackUpdateFailed(ModalConstant.ENTITY.TRAIN_STATION);
  }

  handleRequestSuccessSpecial(trainStation: ITrainStationResponse) {
    this.trainStationStore.update(trainStation.id, trainStation);
    this.setFeedbackUpdateSuccess(ModalConstant.ENTITY.TRAIN_STATION);
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.NAME]: ['', [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required]],
      [CommonConstant.FIELD.ADDRESS]: [
        '',
        [Validators.maxLength(CommonConstant.LIMIT.LENGTH_512), Validators.required],
      ],
    });
  }

  patchDataForm(data: ITrainStationResponse) {
    this.form.patchValue({name: data.name, address: data.address});
    this.onFormChangeValues();
  }
}

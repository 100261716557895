<ng-container *ngIf="!feedback()">
  <div class="modal-body">
    <form class="mx-3 form-group" [formGroup]="form" (ngSubmit)="onSave()">
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputUsername" class="form-label">Tên đăng nhập</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          type="text"
          class="form-control"
          id="inputUsername"
          placeholder="Nhập tên đăng nhập"
          formControlName="username"
          [ngClass]="{
            'is-invalid':
              ((isInvalid || form.get('username')?.touched) && form.get('username')?.invalid) ||
              feedbackErrors['usernameExist'],
          }"
          (focus)="focusInputUsername()"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('username')?.errors?.['required']">Tên đăng nhập không được để trống</p>
          <p *ngIf="feedbackErrors['usernameExist']">Tên đăng nhập đã tồn tại</p>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputPassword" class="form-label">Mật khẩu</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          type="password"
          class="form-control"
          id="inputPassword"
          placeholder="Nhập mật khẩu"
          formControlName="password"
          [ngClass]="{'is-invalid': (isInvalid || form.get('password')?.touched) && form.get('password')?.invalid}"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('password')?.errors?.['minlength']">Độ dài ít nhất 6 ký tự</p>
          <p *ngIf="form.get('password')?.errors?.['maxlength']">Độ dài quá 72 ký tự</p>
          <p *ngIf="form.get('password')?.errors?.['required']">Mật khẩu không được để trống</p>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <label for="inputFullName" class="form-label">Tên người dùng</label>
          <p class="mx-1 text-danger">*</p>
        </div>
        <input
          type="text"
          class="form-control"
          id="inputFullName"
          placeholder="Nhập tên"
          formControlName="fullName"
          [ngClass]="{'is-invalid': (isInvalid || form.get('fullName')?.touched) && form.get('fullName')?.invalid}"
        />
        <div class="invalid-feedback">
          <p *ngIf="form.get('fullName')?.errors?.['maxlength']">Độ dài quá 256 ký tự</p>
          <p *ngIf="form.get('fullName')?.errors?.['required']">Tên người dùng không được để trống</p>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <p class="form-label">Vai trò</p>
          <p class="mx-1 text-danger">*</p>
        </div>
        <div class="w-100">
          <app-selection-single
            [datasets]="roleDatasets"
            [hasInvalid]="
              ((isInvalid || form.get('roleId')?.touched) && form.get('roleId')?.invalid) ||
              feedbackErrors['roleNotFound']
            "
            [isGettingDatasets]="isGettingRoles"
            [ngClass]="{'is-invalid': ((isInvalid || form.get('roleId')?.touched) && form.get('roleId')?.invalid) || feedbackErrors['roleNotFound']}"
            (changeSelectionEvent)="onChangeRoleId($event)"
          ></app-selection-single>
          <div class="invalid-feedback">
            <p *ngIf="form.get('roleId')?.errors?.['required']">Chọn một vai trò</p>
            <p *ngIf="feedbackErrors['roleNotFound']">Vai trò không còn tồn tại</p>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="d-inline-flex">
          <p class="form-label">Đơn vị</p>
          <p class="mx-1 text-danger">*</p>
        </div>
        <div class="w-100">
          <app-selection-single
            [datasets]="companyDatasets"
            [hasInvalid]="
              ((isInvalid || form.get('companyId')?.touched) && form.get('companyId')?.invalid) ||
              feedbackErrors['companyNotFound']
            "
            [hasSearch]="true"
            [isGettingDatasets]="isGettingCompanies"
            [ngClass]="{'is-invalid': ((isInvalid || form.get('companyId')?.touched) && form.get('companyId')?.invalid) || feedbackErrors['companyNotFound']}"
            [position]="'top'"
            (changeSelectionEvent)="onChangeCompanyId($event)"
            (searchValueEvent)="getCompanyDatasets($event)"
          ></app-selection-single>
          <div class="is-invalid invalid-feedback">
            <p *ngIf="form.get('companyId')?.errors?.['required']">Chọn một đơn vị</p>
            <p *ngIf="feedbackErrors['companyNotFound']">Đơn vị không còn tồn tại</p>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="form-label">Kích hoạt</p>
        <div>
          <label class="rcms-switch-brand">
            <input type="checkbox" formControlName="active" />
            <span class="rcms-switch__slider rcms-switch__round"></span>
          </label>
        </div>
      </div>
    </form>
  </div>
  <app-footer-modal
    [buttonSubmitDisabled]="!hasChange"
    [isLoading]="isLoading"
    (eventCancel)="onClose()"
    (eventSubmit)="onSave()"
  ></app-footer-modal>
</ng-container>
<app-feedback-modal [feedback]="feedback()" (eventBack)="onBack()" (eventClose)="onClose()"></app-feedback-modal>
